import React, { useRef, useState } from "react";
import Nav from "../../../components/Patient/Nav/Nav";
import "./ContactUs.css";
import { Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../Redux/Actions/GeneralActions";
import { ApiCall } from "../../../Services/apis";
import Swal from "sweetalert2";
import { useNavigation } from "react-router-dom";
import { useEffect } from "react";
const { TextArea } = Input;

const CHAR_LIMITS = {
  subject: 50,
  title: 150,
  description: 1000,
};

const ContactUs = () => {
  const dispatch = useDispatch();
  const subjectRef = useRef(null);
  const titleRef = useRef(null);
  const emailRef = useRef(null);
  const descrRef = useRef(null);

  const user = useSelector((state) => state.auth.userData);
  const token = useSelector((state) => state.auth.userToken);

  const [data, setData] = useState(user);
  const [error, setError] = useState(null);
  const [charCounts, setCharCounts] = useState({
    subject: 0,
    title: 0,
    description: 0,
  });

  const handleSubmit = async (e) => {
    console.log("first");
    e.preventDefault();

    if (
      !data?.subject ||
      data?.subject?.trim() === "" ||
      !data?.title ||
      data?.title?.trim() === "" ||
      !data?.email ||
      data?.email?.trim() === "" ||
      !data?.description ||
      data?.description?.trim() === ""
    ) {
      setError("Please fill all the fields before submitting.");
      return;
    }

    // Character limit validation
    if (data?.subject.length > CHAR_LIMITS.subject) {
      setError(`Subject must not exceed ${CHAR_LIMITS.subject} characters`);
      return;
    }

    if (data?.title.length > CHAR_LIMITS.title) {
      setError(`Title must not exceed ${CHAR_LIMITS.title} characters`);
      return;
    }

    if (data?.description.length > CHAR_LIMITS.description) {
      setError(
        `Description must not exceed ${CHAR_LIMITS.description} characters`
      );
      return;
    }

    const formData = new FormData();
    data?.subject && formData.append("subject", data?.subject);
    data?.title && formData.append("title", data?.title);
    data?.email && formData.append("email", data?.email);
    data?.description && formData.append("description", data?.description);

    dispatch(setLoader(true));
    try {
      const res = await ApiCall({
        route: `contact/contact_us`,
        verb: "post",
        token: token,
        params: formData,
      });

      if (res?.status == "200") {
        dispatch(setLoader(false));
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Query sent, Admin will contact you soon",
          showConfirmButton: false,
          timer: 1500,
        }).then((result) => {
          if (result.dismiss) {
            titleRef.value = "";
            subjectRef.value = "";
            descrRef.value = "";
            emailRef.value = "";
            window.location.reload(true);
          }
        });
      } else {
        dispatch(setLoader(false));
        Swal.fire(res?.response?.message, "Try an other", "error");
      }
    } catch (e) {
      dispatch(setLoader(false));
      console.log(" new booking err --- ", e?.response?.message);
    }
  };

  return (
    <>
      <Nav />
      <section className="contact-us ">
        <div className="flex justify-center mt-[50px] md:px-[0px] px-[20px]">
          <div className="w-[546px]">
            <div className="flex justify-center ">
              <h1 className="contact-heading pb-3">Contact Us</h1>
            </div>
            {/* <Select
              placeholder="Subject"
              id="gender"
              style={{
                marginTop: "10px",
                width: "100%",
                height: "42px",
                borderRadius: "10px",
              }}
              options={[
                {
                  value: "male",
                  label: "Urgent Care Service",
                },
                {
                  value: "female",
                  label: "In-Person Visit",
                },
                {
                  value: "female",
                  label: "Video Call",
                },
              ]}
            /> */}
            <form
              onChange={({ target: { value, id, files } }) => {
                if (id in CHAR_LIMITS) {
                  if (value.length <= CHAR_LIMITS[id]) {
                    setCharCounts((prev) => ({
                      ...prev,
                      [id]: value.length,
                    }));
                    setData({
                      ...data,
                      [id]: value,
                    });
                  }
                } else {
                  setData({
                    ...data,
                    [id]: value,
                  });
                }
              }}
            >
              <Input
                placeholder="Subject"
                id="subject"
                maxLength={CHAR_LIMITS.subject}
                value={data?.subject || ""}
                style={{
                  marginTop: "10px",
                  width: "100%",
                  height: "45px",
                  borderRadius: "10px",
                }}
                ref={subjectRef}
                showCount={{
                  formatter: ({ count }) => `${count}/${CHAR_LIMITS.subject}`,
                }}
              />

              <Input
                placeholder="Title"
                id="title"
                maxLength={CHAR_LIMITS.title}
                value={data?.title || ""}
                style={{
                  marginTop: "10px",
                  width: "100%",
                  height: "45px",
                  borderRadius: "10px",
                }}
                ref={titleRef}
                showCount={{
                  formatter: ({ count }) => `${count}/${CHAR_LIMITS.title}`,
                }}
              />

              <Input
                placeholder="Email"
                id="email"
                value={user?.email}
                disabled
                style={{
                  marginTop: "10px",
                  width: "100%",
                  height: "45px",
                  borderRadius: "10px",
                  color: "rgba(0, 0, 0, 0.85)",
                }}
                ref={emailRef}
              />

              <TextArea
                style={{
                  marginTop: "10px",
                  borderRadius: "10px",
                }}
                id="description"
                value={data?.description || ""}
                placeholder="Write something......."
                autoSize={{
                  minRows: 6,
                  maxRows: 7,
                }}
                ref={descrRef}
                maxLength={CHAR_LIMITS.description}
                showCount={{
                  formatter: ({ count }) =>
                    `${count}/${CHAR_LIMITS.description}`,
                }}
              />
              {error ? (
                <p className="contact-us-error-status">{error}</p>
              ) : null}
              <center className="mt-[35px]">
                <button
                  className="btn-common"
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                >
                  Submit
                </button>
              </center>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
