import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Swal from "sweetalert2";
import { profileImageURL } from "../../helpers/helpers";
import { useSelector } from "react-redux";
import { ApiCall } from "../../Services/apis";
import logo1 from "../../assets/AmericanClinic Online.svg";
import { useNavigate } from "react-router-dom";
import DrawerApp from "../../components/DrawerApp/DrawerApp";
import ChatDrawer from "./ChatDrawer";
import ChatBox from "./ChatBox";
import { useMediaQuery } from "react-responsive";
import ReportModal from "./ReportModal";
import PrimaryButton from "../../components/UI/PrimaryButton";
import socketConnection from "../messaging/socketConnection";
import ZoomVideo from "@zoom/videosdk";

const Room = ({ roomName, room, handleLogout, userId, roomId }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.userToken);

  const [participants, setParticipants] = useState([]);
  const [users, setUsers] = useState([]);
  const [join, setJoin] = useState(false);
  const [isCamera, setIsCamera] = useState("");
  const [isVideo, setIsVideo] = useState(false);
  const [isAudio, setIsAudio] = useState("");
  const [isMute, setIsMute] = useState(false);
  const [bookingDetail, setBookingDetail] = useState();
  const [timePassed, setTimePassed] = useState(new Date());
  const [timePassedForDelay, setTimePassedForDelay] = useState(new Date());
  const [showReportModal, setShowReportModal] = useState(false);
  const [doctorCompleted, setDoctorCompleted] = useState(false);

  // const zoomClient = useRef(null);
  const zoomClient = ZoomVideo.createClient();

  // console.log(
  //   "Video show status ",
  //   isCamera,
  //   isVideo,
  //   "Microphone show status",
  //   isAudio,
  //   isMute
  // );
  const getBookingDetails = async () => {
    try {
      const res = await ApiCall({
        route: `booking/get_booking_detail/${roomName}`,
        token: token,
        verb: "get",
        params: data,
      });
      if (res?.status == "200") {
        return setBookingDetail(res?.response?.booking_detail);
      } else {
        console.log("your error is there", res?.response);
      }
    } catch (e) {
      console.log("your error is there", e);
    }
  };

  const { isLoading, data } = useQuery("bookingDetails", getBookingDetails);

  useEffect(() => {
    const handleUserAdded = (payload) => {
      console.log(payload, "payload add participant");
      setParticipants((prevParticipants) => [payload]);
      setJoin(true);
    };

    const handleUserRemoved = (payload) => {
      console.log(payload, "payload removed participant");
      setParticipants([]);
    };

    const handleZoomPeer = (payload) => {
      if (payload.action === "Start") {
        // a user turned on their video, render it
        room.attachVideo(payload.userId, 3).then((userVideo) => {
          document
            .querySelector("video-player-container")
            .appendChild(userVideo);
        });
        handleUserAdded(payload);
      } else if (payload.action === "Stop") {
        // a user turned off their video, stop rendering it
        room.detachVideo(payload.userId).then((userVideo) => {
          document.querySelector("video-player-container").innerHTML = "";
        });
        handleUserRemoved(payload);
      }
    };

    zoomClient.on("peer-video-state-change", handleZoomPeer);

    return () => {
      zoomClient.off("peer-video-state-change", handleZoomPeer);
    };
  }, [room, participants]);

  useEffect(() => {
    const userRemoved = (payload) => {
      if (join) {
        setJoin(false);
      }
      console.log("user removed", payload);
    };
    const newUserJoin = (payload) => {
      setJoin(true);
      getBookingDetails();
      console.log("User Joined Called", payload);
    };

    zoomClient.on("user-added", newUserJoin);
    zoomClient.on("user-removed", userRemoved);
  }, [join]);

  async function askPermission() {
    // Checking initial permission state for microphone and camera
    const MicroPermission = await navigator.permissions.query({
      name: "microphone",
    });
    const VideoPermission = await navigator.permissions.query({
      name: "camera",
    });

    console.log("Micro Permission At Start", MicroPermission);
    console.log("Video Permission At Start", VideoPermission);

    try {
      // If microphone permission state is "prompt", ask the user for permission
      if (MicroPermission.state === "prompt") {
        Swal.fire({
          title: "Microphone access!",
          text: "Please allow microphone access to communicate.",
          showConfirmButton: false,
          icon: "info",
          allowOutsideClick: false,
        });

        // Request microphone access
        await navigator.mediaDevices.getUserMedia({ audio: true });

        const againMicroPermission = await navigator.permissions.query({
          name: "microphone",
        });
        if (againMicroPermission.state == "granted") {
          room.startAudio();
          setIsAudio(againMicroPermission.state);
          setIsMute(true);
          console.log(
            "Microphone permission granted:",
            againMicroPermission.state,
            isAudio
          );
        } else if (againMicroPermission.state == "denied") {
          setIsAudio(againMicroPermission.state);
          setIsMute(false);
          console.log(
            "Microphone permission denied:",
            againMicroPermission.state,
            isAudio
          );
          // Handle the case when permission is denied
          Swal.fire({
            title: "Microphone Access Denied",
            text: "You have denied microphone access. Some features may not work properly.",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
        Swal.close();
        console.log(
          "Again Microphone Permission Check",
          againMicroPermission,
          isAudio
        );
      } else {
        setIsAudio(MicroPermission.state);
      }
    } catch (error) {
      // Handle any errors (e.g., if the user denies access)
      console.log("Error requesting microphone permission:", error);
      if (
        error.name === "NotAllowedError" ||
        error.name === "PermissionDeniedError"
      ) {
        Swal.fire({
          title: "Microphone Access Denied",
          text: "You have denied microphone access. Some features may not work properly.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    }

    try {
      // If video permission state is "prompt", ask the user for permission
      if (VideoPermission.state === "prompt") {
        Swal.fire({
          title: "Camera access!",
          text: "Please allow camera access to communicate.",
          showConfirmButton: false,
          icon: "info",
          allowOutsideClick: false,
        });

        // Request microphone access
        await navigator.mediaDevices.getUserMedia({ video: true });

        const againVideoPermission = await navigator.permissions.query({
          name: "camera",
        });
        if (againVideoPermission.state == "granted") {
          setIsCamera(againVideoPermission.state);
          setIsVideo(true);
          console.log(
            "Camera permission granted:",
            againVideoPermission.state,
            isCamera
          );

          room.startVideo().then(() => {
            room
              .attachVideo(zoomClient.getCurrentUserInfo().userId, 3)
              .then((userVideo) => {
                document
                  .querySelector("video-local-player-container")
                  .appendChild(userVideo);
              });
          });
        } else if (againVideoPermission.state == "denied") {
          setIsCamera(againVideoPermission.state);
          setIsVideo(false);
          console.log(
            "Camera permission denied:",
            againVideoPermission.state,
            isCamera
          );
          // Handle the case when permission is denied
          Swal.fire({
            title: "Camera Access Denied",
            text: "You have denied microphone access. Some features may not work properly.",
            icon: "warning",
            confirmButtonText: "Ok",
          });
        }
        Swal.close();
        console.log(
          "Again Camera Permission Check",
          againVideoPermission,
          isCamera
        );
      } else {
        setIsCamera(VideoPermission.state);
      }
    } catch (error) {
      // Handle any errors (e.g., if the user denies access)
      console.log("Error requesting camera permission:", error);
      if (
        error.name === "NotAllowedError" ||
        error.name === "PermissionDeniedError"
      ) {
        Swal.fire({
          title: "Camera Access Denied",
          text: "You have denied camera access. Some features may not work properly.",
          icon: "warning",
          confirmButtonText: "Ok",
        });
      }
    }
  }

  // useEffect(() => {
  //   askPermission();
  // }, []);

  useEffect(() => {
    // Delay the permission check to ensure Zoom client and room are ready
    const timer = setTimeout(() => {
      askPermission();
    }, 500);

    return () => clearTimeout(timer);
  }, [room]);

  useEffect(() => {
    socketConnection.on("doctorCompleted", (data) => {
      console.log("doctor left the video");
      console.log(data?.booking_id, roomId, data?.booking_id == roomId);
      if (data?.booking_id == roomId) setDoctorCompleted(true);
    });
  }, []);

  // on metting leave
  const onMeetingLeave = (id) => {
    if (bookingDetail?.doctor_id?.user_id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to end consultation?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes",
        confirmButtonColor: "#c82333",
      }).then(async (result) => {
        if (result.isConfirmed) {
          handleLogout(true);
        }
      });
    } else {
      if (!bookingDetail?.doctor_id?.user_id) {
        Swal.fire({
          title: "Are you sure?",
          text: "Do you want report delay from doctor and reschedule?",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "No",
          confirmButtonText: "Yes",
          confirmButtonColor: "#c82333",
        }).then(async (result) => {
          if (result.isConfirmed) {
            setShowReportModal(true);
          }
        });
      }
    }
  };
  // main return

  const sendNotificationHandler = async () => {
    try {
      const res = await ApiCall({
        route: `receiptionist/add_notifications_for_receptionists`,
        token: token,
        verb: "post",
        params: {
          bookingId: bookingDetail?._id,
        },
      });
      console.log(res?.response, "response");
      if (res?.status == "200") {
        console.log("res", res?.response);
        Swal.fire(
          "Your request for urgent attention has been notified.",
          "Staff will be in contact with you soon.",
          "success"
        );
        setTimePassed(new Date());
      } else {
        console.log("your error is there", res?.response);
      }
    } catch (err) {
      console.log("Coudln't send", err);
    }
  };

  const reportAndRescheduleHandler = async () => {
    setShowReportModal(true);
  };

  const closeReportModal = () => {
    setShowReportModal(false);
  };

  let items = [];

  if (
    participants?.length <= 0 &&
    !bookingDetail?.doctor_id?.user_id &&
    (new Date() - timePassedForDelay) / (1000 * 60) > 20
  ) {
    items.push({
      id: "1",
      label: (
        <PrimaryButton
          className="report-button "
          onClick={reportAndRescheduleHandler}
        >
          Report Delay & Reschedule
        </PrimaryButton>
      ),
    });
  }

  if (
    participants?.length <= 0 &&
    !bookingDetail?.doctor_id?.user_id &&
    (new Date() - timePassed) / (1000 * 60) > 4
  ) {
    items.push({
      id: "2",
      label: (
        <PrimaryButton
          className="notify-receptionist"
          onClick={sendNotificationHandler}
          icon={
            <i
              style={{ transform: `rotate(0deg)` }}
              class="fa-solid fa-phone"
            ></i>
          }
        >
          Ring again
        </PrimaryButton>
      ),
    });
  }

  const toggleVideo = async () => {
    console.log("Valuevale", isVideo);
    if (isCamera === "granted") {
      if (isVideo) {
        console.log("video is allowed", isVideo);
        room.stopVideo().then(() => {
          room
            .detachVideo(zoomClient.getCurrentUserInfo().userId)
            .then((userVideo) => {
              document.querySelector("video-local-player-container").innerHTML =
                "";
            });
        });
        setIsVideo(false);
      } else {
        room.startVideo().then(() => {
          room
            .attachVideo(zoomClient.getCurrentUserInfo().userId, 3)
            .then((userVideo) => {
              console.log("userVideo", userVideo);
              document
                .querySelector("video-local-player-container")
                .appendChild(userVideo);
            });
        });
        setIsVideo(true);
      }
    } else {
      Swal.fire({
        title: "Camera Permission",
        text: "You have denied camera access. Please allow from the setting.",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }
  };

  useEffect(() => {
    if (zoomClient && room) {
      zoomClient
        .getAllUser()
        .filter((user) => user.userIdentity !== userId)
        .forEach(async (user) => {
          if (user.userIdentity !== userId) {
            console.log(user, "user already joined");
            if (user.bVideoOn) {
              room
                .detachVideo(user.userId)
                .then((userVideo) => {
                  document.querySelector("video-player-container").innerHTML =
                    "";
                })
                .then(() => {
                  room.attachVideo(user.userId, 3).then((userVideo) => {
                    document
                      .querySelector("video-player-container")
                      .appendChild(userVideo);
                  });
                });

              setParticipants([user]);
            }
            setJoin(true);
            setUsers(user);
          }
        });
    }
  }, [zoomClient, room]);

  const toggleAudio = async () => {
    try {
      console.log("ismute value is", isMute);
      if (isAudio === "granted") {
        await room.startAudio();
        if (isMute) {
          await room.muteAudio();
          setIsMute(false);
        } else {
          await room.unmuteAudio();
          setIsMute(true);
        }
      } else {
        Swal.fire({
          title: "Microphone Access Denied",
          text: "You have denied microphone access. Please allow from the setting.",
          icon: "warning",
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      console.error("Error toggling audio:", error);
    }
  };

  console.log(participants);

  return (
    <>
      <div className="main-container-video-chat">
        <div className="video-chat-container-header">
          {!isMobile && (
            <DrawerApp
              getBookingDetails={getBookingDetails}
              bookingDetail={bookingDetail}
            />
          )}
          <img
            onClick={() => navigate("/my-consultation")}
            src={logo1}
            alt="logo"
            className="video-chat-header-logo"
          />

          <button
            className="custom-button-video-call mobile-end-consultation end-consultation"
            onClick={() => onMeetingLeave(bookingDetail?._id)}
          >
            End
          </button>
        </div>
        <div className="video-chat-section-container-main">
          <div className="video-chat-inner-contianer">
            <div className="videoDiv-main">
              <div
                className="remote-video"
                style={{ display: participants.length == 0 ? "none" : "" }}
              >
                <video-player-container></video-player-container>
              </div>

              {join ? (
                participants.length == 0 ? (
                  <div class="video-call-ended-text-in-box">
                    <p>Doctor has turned off their camera!</p>
                  </div>
                ) : (
                  ""
                )
              ) : (
                <div class="video-call-ended-text-in-box">
                  <h4 class="h4">Consultation Paused!</h4>
                  <p>
                    {bookingDetail?.doctor_id?.user_id
                      ? " We've notified the doctor. "
                      : " "}
                    Doctor will be in the session soon, please don't hesitate to
                    ask any questions in chat.
                  </p>
                </div>
              )}
              <div className="local-user-video">
                {isCamera === "granted" ? (
                  isVideo ? (
                    <div className="local-video">
                      <video-local-player-container></video-local-player-container>
                    </div>
                  ) : (
                    <div className="local-user-off-cam">
                      <i class={`fa-solid fa-video-slash text-7`}></i>
                      <p>Camera turned off.</p>
                    </div>
                  )
                ) : (
                  ""
                )}
                <video-local-player-container></video-local-player-container>
              </div>

              {isMobile && (
                <div className="mobile-long-buttons-on-top">
                  {items?.length == 2
                    ? items?.find((el) => el?.id == "1")?.label
                    : items?.find((el) => el?.id == "2")?.label}
                </div>
              )}
              <div className="video-chat-controls">
                {isMobile && (
                  <DrawerApp
                    getBookingDetails={getBookingDetails}
                    bookingDetail={bookingDetail}
                  />
                )}
                {isMobile && (
                  <ChatDrawer
                    selectedChat={{
                      _id: bookingDetail?.chat_id,
                      booking: bookingDetail,
                    }}
                  />
                )}

                <button
                  className={
                    isCamera === "granted"
                      ? "chat-control-button video-active"
                      : "chat-control-button"
                  }
                  onClick={() => toggleVideo()}
                  style={{
                    backgroundColor:
                      isCamera === "granted"
                        ? isVideo
                          ? "blue"
                          : "red"
                        : "orange",
                  }}
                  title={
                    isCamera == "granted"
                      ? "Video Management"
                      : "Please provide video permissions"
                  }
                >
                  <i
                    class={`fa-solid fa-video${isVideo ? "" : "-slash"} text-7`}
                  ></i>
                </button>

                <button
                  className={
                    isAudio === "granted"
                      ? "chat-control-button video-active"
                      : "chat-control-button"
                  }
                  onClick={() => toggleAudio()}
                  style={{
                    backgroundColor:
                      isAudio === "granted"
                        ? isMute
                          ? "blue"
                          : "red"
                        : "orange",
                  }}
                  title={
                    isMute
                      ? "Audio Management"
                      : "Please provide audio permissions"
                  }
                >
                  <i
                    class={`fa-solid fa-microphone${
                      isMute ? "" : "-slash"
                    } text-3`}
                  ></i>
                </button>
              </div>
              <div className="video-chat-main-buttons">
                <PrimaryButton
                  className="end-consultation"
                  onClick={() => onMeetingLeave(bookingDetail?._id)}
                >
                  End Consultation
                </PrimaryButton>
                {items?.length == 2
                  ? items?.find((el) => el?.id == "1")?.label
                  : items?.find((el) => el?.id == "2")?.label}
              </div>
            </div>
          </div>

          {bookingDetail && !isMobile && (
            <ChatBox
              selectedChat={{
                _id: bookingDetail?.chat_id,
                booking: bookingDetail,
              }}
            />
          )}
        </div>
      </div>
      <ReportModal
        open={showReportModal}
        close={closeReportModal}
        bookingId={bookingDetail?._id}
        handleLogout={handleLogout}
      />
    </>
  );
};

export default Room;
