import React, { useState, useEffect, useCallback } from "react";

import "../InPersonAppointment/Appointment.css";
import Logo from "../../../assets/Images/AmericanClinic Online.svg";
import { Link, useNavigate } from "react-router-dom";
import { Button, Steps } from "antd";
import PersonalInfo from "../InPersonAppointment/PersonalInfo";
import MedicalHistory from "../InPersonAppointment/MedicalHistory";
import UrgentConfirmation from "./UrgentConfirmation";
import Payment from "./Payment";
import { useDispatch, useSelector } from "react-redux";
import { signUpGuestRequest } from "../../../Redux/Actions/AuthActions";
import Swal from "sweetalert2";
import { Modal } from "antd";
import CheckTerms from "../../Common/CheckTerms";
import { setCategioryId } from "../../../Redux/Actions/GeneralActions";
import { textAlign } from "@mui/system";
import "./UrgentAppointment.css";
import { ApiCall } from "../../../Services/apis";

const { Step } = Steps;

let goToNextStepInForm = false;

const UrgentAppointment = () => {
  const [modal, setModal] = useState();
  const [terms, setTerms] = useState(false);
  const [handle, setHandle] = useState(false);
  const [errors, setErrors] = useState({});
  const user = useSelector((state) => state.auth.userData);
  const token = useSelector((state) => state.auth.userToken);
  const diseaseInfo = useSelector((state) => state.auth);
  const [consentContent, setConsentContent] = useState(null);
  const [current, setCurrent] = useState(0);
  const [allergy, setAllergy] = React.useState("");
  const [medications, setMedications] = React.useState("");
  // const token = useSelector((state) => state.auth.userToken);
  // const categioryID = useSelector((state) => state.auth.categioryId);

  useEffect(() => {
    const getConsent = async () => {
      try {
        const res = await ApiCall({
          route: `documents/get_document/Consent`,
          verb: "get",
          params: {},
        });
        if (res?.status === 200) {
          setConsentContent(res?.response?.document);
        }
      } catch (error) {}
    };
    getConsent();
  }, []);

  const navigate = useNavigate();

  console.log("diseaseInfo and came on this page", diseaseInfo);
  useEffect(() => {
    if (
      !diseaseInfo?.categioryId?.categorieId ||
      !diseaseInfo?.categioryId?.deseaseId
    ) {
      console.log("not working");
      navigate("/prescription/urgent");
    }
  }, [diseaseInfo]);

  const dispatch = useDispatch();
  console.log(errors);
  const [data, setData] = useState({
    ...user,
    medication: [""],
    Female_type: "",
  });
  console.log(data);
  const validate = () => {
    let errors = {};
    if (!data?.first_name) errors.first_name = "First name is required";
    if (!data?.last_name) errors.last_name = "Last name is required";
    if (!data?.email) errors.email = "Email is required";
    if (!data?.gender) errors.gender = "Gender is required";
    if (!data?.dob) errors.dob = "DOB is required";

    const dob = new Date(data?.dob);
    const currentDate = new Date();
    const userAge =
      currentDate.getFullYear() -
      dob.getFullYear() -
      (currentDate <
      new Date(currentDate.getFullYear(), dob.getMonth(), dob.getDate())
        ? 1
        : 0);

    if (data?.dob && userAge < 15) {
      errors.dob = "Patient must be at least 15 years old.";
    }

    setErrors(errors);
    return Object.keys(errors).length;
  };
  const validateHistory = () => {
    let errors = {};
    if (!data?.booking_reason)
      errors.booking_reason = "Please Provide the Answer";
    if (data?.medication[0].trim() == "")
      errors.medication = "Please provide your medication history";
    if (!data?.allergy) errors.allergy = "Fill the allergic section";
    if (!data?.past_history)
      errors.past_history = "Please Provide the Medical History";

    setErrors(errors);
    return Object.keys(errors).length;
  };
  const steps = [
    {
      title: "Personal info",
      content: (
        <PersonalInfo
          data={data}
          setData={setData}
          name="urgent"
          validate={validate}
          errors={errors}
          setErrors={setErrors}
        />
      ),
    },
    {
      title: "Medical history",
      content: (
        <MedicalHistory
          data={data}
          allergy={allergy}
          setAllergy={setAllergy}
          medications={medications}
          setMedications={setMedications}
          validateHistory={validateHistory}
          setData={setData}
          errors={errors}
          setErrors={setErrors}
        />
      ),
    },
    {
      title: "Pay & Book",
      content: <Payment data={data} setData={setData} />,
    },
  ];
  const [show, setShow] = useState(true);
  const go = () => {
    if (current == 0) {
      const valid = validate();
      if (valid > 0) return;
      setCurrent(current + 1);
    } else if (current == 1) {
      const valid = validateHistory();
      if (valid > 0) {
        return;
      } else {
        setModal(true);
      }
    }
  };

  const goo = () => {
    goToNextStepInForm = true;
  };

  const next = () => {
    console.log("added data", data);
    const params = {
      first_name: data?.first_name,
      last_name: data?.last_name,
      gender: data?.gender,
      phone_number: data?.phone_number,
      japan_email: data?.japan_email,
      email: data?.email,
      dob: data?.dob,
      location: data?.location || null,
    };
    if (user == null) {
      if (current == 0) {
        const valid = validate();
        if (valid > 0) return;
      } else if (current == 1) {
        const valid = validateHistory();
        if (valid > 0) {
          return;
        }
      }
      dispatch(signUpGuestRequest({ params, goo, setData }));
    } else {
      go();
    }
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  useEffect(() => {
    if (user?.guest && goToNextStepInForm) {
      goToNextStepInForm = false;
      go();
    }
  }, [user?.guest]);

  const termsValidate = () => {
    if (!terms) {
      Swal.fire({
        title: "Please agree to the consent form",
        icon: "error",
        timer: "3000",
        showConfirmButton: false,
      });
    } else {
      setTerms(false);
      setModal(false);
      setCurrent(current + 1);
    }
  };

  const nextSkip = () => {
    setModal(true);
  };

  console.log(current, "current step ssss");

  return (
    <section className="Bg">
      {modal ? (
        <>
          <Modal
            title="Consent"
            open={modal}
            centered
            onCancel={() => setModal(false)}
            width={900}
            // className="ant-modal-wrap "
          >
            <div className="flex justify-center ">
              <div className="disclaimer-box mt-[16px] p-[20px] sectionScroll modalConsent">
                <p>
                  Last updated:{" "}
                  {new Date(consentContent?.updatedAt)?.toLocaleDateString()}
                </p>
                <div className="consent-content">
                  {consentContent?.content ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: consentContent.content,
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <p className="mt-[12px] bold-text">Contact Us</p>
                <p>
                  If you have any questions about this Disclaimer, You can
                  contact Us:
                </p>
                <p className="mt-[12px]">
                  By email:{" "}
                  <a
                    href="https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox?compose=GTvVlcSMSqctFrPBFcgMPfnHMhhDFLVHxHFtgwwbvbnbmFqdxcfdrqShKQRmdpjCZZVtQPHsKHvKn"
                    target="_blank"
                  >
                    {" "}
                    info@americanclinicotokyo.com
                  </a>
                </p>
              </div>
            </div>
            <div className="remember radio_check">
              <CheckTerms
                onChange={(e) => {
                  setTerms(e.target.checked);
                }}
                value={terms}
                callback={() => setTerms(!terms)}
              />
            </div>
            <div className="w-[100%] py-[20px] flex justify-center">
              {current < steps.length - 1 && (
                <Button
                  className="continue-btn"
                  onClick={() => {
                    termsValidate();
                  }}
                >
                  Next
                </Button>
              )}
            </div>
          </Modal>
        </>
      ) : null}
      {show ? (
        <>
          <div className="w-[100%] widthcontainer  flex flex-col  ">
            <div className="w-[100%] md:flex md:justify-between md:px-[50px] px-[20px] md:h-[15vh] md:pt-[0px] pt-[15px] items-center">
              <Link to="/">
                <img
                  src={Logo}
                  height="150px"
                  width="150px"
                  className="logoImg  "
                  onClick={() => dispatch(setCategioryId(null))}
                ></img>
              </Link>
              <div className="md:mt-[0px] mt-[20px]">
                <Steps current={current} labelPlacement="vertical">
                  {steps.map((item) => (
                    <Step key={item.title} description={item.title} />
                  ))}
                </Steps>
              </div>
            </div>
            <div className="VideoBg md:h-[85vh] h-[100%] overflow-y-auto">
              <>
                <div className="flex  flex-col ">
                  <div className="w-[100%]">
                    <div className="">
                      <div className="steps-content w-[100%]">
                        {steps[current].content}
                      </div>
                      <div className="flex md:justify-end md:pl-[80px] md:pr-[68px] pb-[30px] pr-[20px]">
                        <div className="steps-action flex justify-between  md:w-[50%] mb-[-10px] w-[100%] mt-[20px] md:pl-[20px]">
                          {current > 0 && (
                            <Button
                              className="previous-btn"
                              onClick={() => prev()}
                            >
                              Previous
                            </Button>
                          )}
                          {current == 1 && (
                            <div
                              className=" md:ml-[100px] md:pr-[2px] "
                              onClick={() => nextSkip()}
                              style={{
                                color: `#002fa7`,
                                fontFamily: "Poppins",
                                fontWeight: "600",
                                fontSize: "14px",
                                borderStyle: "none",
                                boxShadow: "none",
                                backgroundColor: "#edf2f",
                                marginTop: "9px",
                                cursor: "pointer",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Skip this step
                            </div>
                          )}
                          {current == 0 && (
                            <Link to="/">
                              <Button
                                className="cancel-btn"
                                onClick={() => dispatch(setCategioryId(null))}
                              >
                                Cancel
                              </Button>
                            </Link>
                          )}
                          {current < steps.length - 1 && (
                            <Button
                              className="continue-btn "
                              onClick={() => next()}
                            >
                              Next
                            </Button>
                          )}
                          {/* {current === steps.length - 1 && (
                            <div className="flex justify-end">
                              <Button
                                className="continue-btn"
                                onClick={() => setHandle(!handle)}
                              >
                                Done
                              </Button>
                            </div>
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </>
      ) : (
        <UrgentConfirmation />
      )}
    </section>
  );
};

export default UrgentAppointment;
