import React, { useState, useEffect, useRef } from "react";
import { Stethoscope } from "../../../assets/Images";
import moment from "moment";
import { DatePicker } from "antd";
import { Input, Select } from "antd";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import PhoneNumber from "../../Common/PhoneNumber";
import ColumnGroup from "antd/lib/table/ColumnGroup";
import { message } from "antd";
const access_token =
  "pk.eyJ1IjoiaW5hYW0xIiwiYSI6ImNramtmNmljYzJhMWMycnFwM28zOHE4ZzIifQ.pBIP97q3Us332iKImTP9aQ";

const PersonalInfo = ({ data, setData, name, validate, errors, setErrors }) => {
  const user = useSelector((state) => state.auth.userData);
  const [locationAvailable, setLocationAvailable] = useState(true);
  const [contextHolder] = message.useMessage();

  const [suggetions, setSuggetions] = useState([]);
  const [shown, setshown] = useState();
  const [phoneNum, setphoneNum] = useState(
    user?.phone_number || data?.phone_number
  );
  const [location, setLocation] = useState(
    user?.location?.address || data?.location
  );
  const [location_label, setLocation_label] = useState(data?.address);
  const [latitude, setLatitude] = useState(
    user?.location?.coordinates?.[1] || data?.location?.coordinates?.[1]
  );
  const [longitude, setLongitude] = useState(
    user?.location?.coordinates?.[0] || data?.location?.coordinates?.[0]
  );

  useEffect(() => {
    setData({
      ...data,
      location: { address: location_label, coordinates: [longitude, latitude] },
    });
    // }
  }, [location_label, phoneNum]);

  console.log(data, "dataaa");

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const handleChange = (value) => {
    setData({ ...data, gender: value });
  };

  const onChange = (event) => {
    const selectedDate = event.target.value;

    setData({
      ...data,
      dob: selectedDate,
    });
  };

  // Disable future dates
  const maxDate = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format

  const dateFormat = "YYYY-MM-DD";
  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };
  const disabledDateTime = () => ({
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  });
  const getcurrentLocation = (longitude, latitude) => {
    fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?types=poi&access_token=${access_token}`
    )
      .then((response) => response.json())
      .then((data) => {
        setLocation_label(data?.features[0]?.place_name);
        setLocation(data?.features[0]?.place_name);
        setLongitude(longitude);
        setLatitude(latitude);
      });
  };
  const getlocationPermison = async () => {
    if (!navigator.geolocation) {
      setLocationAvailable(false);
      message.error({
        content:
          "Permission to access your device location is blocked. Please check your browser's settings.",
        key: "permissionError",
      });
    } else {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          getcurrentLocation(
            position.coords.longitude,
            position.coords.latitude
          );
          setLongitude(position.coords.longitude);
          setLatitude(position.coords.latitude);
        },
        (error) => {
          setLocationAvailable(false);
          message.error({
            content:
              "Permission to access your device location is blocked. Please check your browser's settings.",
            key: "permissionError",
          });
          console.log("Error getting location:", error);
        }
      );
    }
  };

  return (
    <>
      <div className="first-step "></div>
      <div className=" w-[100%] md:flex justify-between personal-bgg">
        {/* {contextHolde r} */}

        <div className="md:w-[50%] ">
          <div className="md:pl-[120px] pl-[20px] personal mt-[60px]">
            <h1>Personal Info</h1>
            <p className="md:pr-[50%] pr-[20px]">
              For safety reasons, the patient must be present to answer personal
              health questions. A parent or legal guardian must be present with
              a minor at all times.
            </p>
            <div className="flex mt-[40px] border-b">
              <h6>
                {name == "urgent"
                  ? "Urgent Care"
                  : name == "video"
                  ? "Video Call"
                  : "In-Person"}
              </h6>
              <Stethoscope className=" ml-[10px]" />
            </div>
          </div>
          <div className="flex  items-end ]">
            {/* <img
              src={personalBg}
              className="md:block hidden absolute bottom-0"
            /> */}
          </div>
        </div>
        <div className="md:w-[50%] md:pr-[68px] md:pl-[0px] pr-[20px] pl-[20px]">
          <h6 className="mt-[15px] md:mt-0 text-[#002FA7]">
            Please complete the following form for{" "}
            {name === "urgent"
              ? "Urgent Care"
              : name === "video"
              ? "Video Call"
              : "In-Person"}{" "}
            consultation.
          </h6>
          <form
            onChange={({ target: { value, id } }) => {
              console.log("hlo", location);
              setErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));

              setData({
                ...data,
                ...(id && { [id]: value }),
                phone_number: phoneNum,
                address: location,
              });
            }}
          >
            <div className="my-[15px] w-[100%] mt-[10px]">
              <Input
                placeholder="First name"
                id="first_name"
                value={data?.first_name}
                style={{
                  marginTop: "10px",
                  width: "100%",
                  height: "42px",
                  borderRadius: "10px",
                }}
              />
              {errors?.first_name && (
                <p
                  style={{
                    color: "red",
                    fontSize: "11px",
                    margin: "2px 0px 0px 2px",
                  }}
                >
                  {errors?.first_name}
                </p>
              )}
              <Input
                placeholder="Last name"
                id="last_name"
                value={data?.last_name}
                style={{
                  marginTop: "10px",
                  width: "100%",
                  height: "42px",
                  borderRadius: "10px",
                }}
              />
              {errors?.last_name && (
                <p
                  style={{
                    color: "red",
                    fontSize: "11px",
                    margin: "2px 0px 0px 2px",
                  }}
                >
                  {errors?.last_name}
                </p>
              )}
              <Input
                placeholder="Email Address"
                id="email"
                value={data?.email}
                style={{
                  marginTop: "8px",
                  width: "100%",
                  height: "42px",
                  borderRadius: "10px",
                }}
              />
              {errors?.email && (
                <p
                  style={{
                    color: "red",
                    fontSize: "11px",
                    margin: "2px 0px 0px 2px",
                  }}
                >
                  {errors?.email}
                </p>
              )}

              <>
                {user ? (
                  <>
                    <input
                      type="date"
                      onChange={onChange}
                      defaultValue={
                        user?.dob ? user?.dob.split("T")[0] : maxDate
                      }
                      max={maxDate} // Disable future dates
                      id="dob"
                      style={{
                        color: data?.dob ? "#000" : "#999",
                        marginTop: "8px",
                        width: "100%",
                        height: "42px",
                        borderRadius: "10px",
                        padding: "10px",
                        border: "1px solid #d9d9d9",
                      }}
                      placeholder="Date of birth"
                    />
                    {errors?.dob && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "11px",
                          margin: "2px 0px 0px 2px",
                        }}
                      >
                        {errors?.dob}
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    <input
                      type="date"
                      onChange={onChange}
                      max={maxDate} // Disable future dates
                      id="dob"
                      style={{
                        color: data?.dob ? "#000" : "#999",
                        marginTop: "8px",
                        width: "100%",
                        height: "42px",
                        borderRadius: "10px",
                        padding: "10px",
                        border: "1px solid #d9d9d9",
                      }}
                      placeholder="Date of birth"
                    />
                    {errors?.dob && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "11px",
                          margin: "2px 0px 0px 2px",
                        }}
                      >
                        {errors?.dob}
                      </p>
                    )}
                  </>
                )}
              </>

              <Select
                placeholder="Select Gender"
                style={{
                  marginTop: "10px",
                  width: "100%",
                  height: "42px",
                  borderRadius: "10px",
                }}
                allowClear
                onChange={handleChange}
                defaultValue={data?.gender == "" ? undefined : data?.gender}
                options={[
                  {
                    value: "male",
                    label: "Male",
                  },
                  {
                    value: "female",
                    label: "Female",
                  },
                ]}
              />
              {errors?.gender && (
                <p
                  style={{
                    color: "red",
                    fontSize: "11px",
                    margin: "4px 0px 0px 2px",
                  }}
                >
                  {errors?.gender}
                </p>
              )}
              <>
                <div
                  className="bg-[#fff]"
                  style={{
                    marginTop: "10px",
                    width: "100%",
                    height: "42px",
                    borderRadius: "10px",
                    border: "1px solid #d9d9d9",
                    display: "flex",
                  }}
                >
                  <div className="w-full" title="Click to get your location">
                    {/* <Input
                      // disabled
                      placeholder="Geo Location"
                      // id="address"
                      // value={data?.address}
                      // onChange={(e) => {
                      //   getSuggestion(e.target.value);
                      //   // setAddress(location_label);
                      //   handleLocation();
                      // }}

                      value={location_label}
                      style={{
                        // width: "90%",
                        height: "40px",
                        borderRadius: "10px 0px 0px 10px",
                        border: "none",
                      }}
                      onClick={() => {
                        getlocationPermison();
                        setshown(false);
                      }}
                    /> */}
                    <div
                      onClick={() => {
                        getlocationPermison();
                        setshown(false);
                      }}
                      style={{
                        height: "40px",
                        borderRadius: "10px 0px 0px 10px",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "10px",
                        cursor: "pointer",
                        ...(!location_label && { color: "grey" }),
                      }}
                    >
                      {location_label || "Click to get your location"}
                    </div>
                    {errors?.location && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "10px",
                          marginTop: "2px",
                        }}
                      >
                        {errors?.location}
                      </p>
                    )}
                    {shown ? (
                      <>
                        {" "}
                        {suggetions?.length > 0 && (
                          <ul
                            className={`z-40 bg-white ${
                              suggetions?.length > 0 ? "h-[100px]" : "h-[0px]"
                            }  ml-5 absolute w-[33%] overflow-y-scroll px-3 mt-[5px]`}
                          >
                            {suggetions.map((item) => (
                              <li
                                onClick={() => {
                                  setshown(false);
                                  setLocation_label(item?.place_name);
                                  setLocation(item?.place_name);
                                  setLongitude(item?.center[0]);
                                  setLatitude(item?.center[1]);
                                  // handleSubmit();
                                  // mainSearchFun();
                                }}
                                className="hover:bg-blue-300 cursor-pointer"
                              >
                                {item?.place_name}
                              </li>
                            ))}
                          </ul>
                        )}{" "}
                      </>
                    ) : null}
                  </div>
                  <div
                    onClick={() => {
                      getlocationPermison();
                      setshown(false);
                    }}
                    className="bg-[#e8f4fe]  cursor-pointer w-[4rem]  rounded-r-[10px]  flex justify-center items-center"
                  >
                    <i className="fa-solid fa-location-crosshairs text-[#002fa7]   text-2xl   "></i>
                  </div>
                </div>
              </>
              <Input
                placeholder="Your reachable address in Japan"
                id="japan_email"
                defaultValue={
                  data?.japan_email && data.japan_email !== "undefined"
                    ? data?.japan_email
                    : ""
                }
                style={{
                  marginTop: "8px",
                  width: "100%",
                  height: "42px",
                  borderRadius: "10px",
                }}
                value={
                  data?.japan_email && data.japan_email !== "undefined"
                    ? data?.japan_email
                    : ""
                }
                autoComplete="off"
              />
              {errors?.japan_email && (
                <p
                  style={{
                    color: "red",
                    fontSize: "11px",
                    margin: "5px 0px 0px 5px",
                  }}
                >
                  {errors?.japan_email}
                </p>
              )}
              <Input
                placeholder="Phone Number"
                onChange={(e) => setphoneNum(e.target.value)}
                value={phoneNum}
                type="number"
                style={{
                  marginTop: "8px",
                  width: "100%",
                  height: "42px",
                  borderRadius: "10px",
                }}
              />
              {/* <PhoneNumber setphoneNum={setphoneNum} errors={errors} /> */}
              {errors?.phone_number && (
                <p
                  style={{
                    color: "red",
                    fontSize: "11px",
                    margin: "19px 0px 0px 5px",
                  }}
                >
                  {errors?.phone_number}
                </p>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default PersonalInfo;
